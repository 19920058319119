import { createContext, useContext, useRef, ReactNode, useState } from 'react';
import { Toast } from 'primereact/toast';

interface ToastOptions {
    severity?: "success" | "info" | "warn" | "error";
    summary?: string;
    detail?: string;
    sticky?: boolean
}

type ToastContextType = (options: ToastOptions) => void;

const ToastContext = createContext<ToastContextType>(() => {});

export const useToast = (): ToastContextType => {
    const context = useContext(ToastContext);
    if (!context) {
        throw new Error('useToast must be used within a ToastProvider');
    }
    return context;
};

interface ToastProviderProps {
    children: ReactNode;
}

const ToastProvider = ({ children }: ToastProviderProps): JSX.Element => {
    const toastRef = useRef<Toast>(null);
    const [visible, setVisible] = useState(false);

    const showToast = (options: ToastOptions) => {
        if (!visible) {
            setVisible(true);
            toastRef.current?.clear();
            toastRef.current?.show(options);
        }
    };
    
    const clear = () => {
        toastRef.current?.clear();
        setVisible(false);
    };
    return (
        <ToastContext.Provider value={showToast}>
            {children}
            <Toast ref={toastRef} className='!right-0 sm:!right-5 text-sm' onRemove={clear}/> 
        </ToastContext.Provider>
    );
};

export default ToastProvider;
