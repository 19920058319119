import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './redux/root.reducer';


const store = configureStore({
  reducer: rootReducer
});

export type RootState = ReturnType<typeof rootReducer>;

export default store;