import { combineReducers } from "redux";
import authReducers from "./auth/auth.reducer";
import usersReducers from "./users/users.reducer";
import productsReducers from "./inventory/products.reducer";
import machinesReducers from "./machines/machines.reducer";

const rootReducer = combineReducers({
    auth: authReducers,
    users: usersReducers,
    products: productsReducers,
    machines: machinesReducers
});

export default rootReducer;