import { IMachines } from "../../pages/Machines/Machine.type";
import { MachineActionTypes, MachinesActionTypes } from "./machines.action";

export interface State {
    machines: IMachines[],
    isLoading: boolean,
    error: string | undefined,
    machine: IMachines | undefined
}

const machinesInitialState: State = {
    machines: [],
    isLoading: false,
    error: undefined,
    machine: undefined
}

const machinesReducers = (state = machinesInitialState, action: MachineActionTypes) => {
    switch (action.type) {
        case MachinesActionTypes.CREATE_MACHINE_IS_LOADING:
            return {
                ...state,
                isLoading: true
        }
        case MachinesActionTypes.CREATE_MACHINE_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload
        }
        case MachinesActionTypes.CREATE_MACHINE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                machine: action.payload
        }
        case MachinesActionTypes.SET_ALL_MACHINES_IS_LOADING:
            return {
                ...state,
                isLoading: true
            }
        case MachinesActionTypes.SET_ALL_MACHINES_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload
            }
        case MachinesActionTypes.SET_ALL_MACHINES_SUCCESS:
            return {
                ...state,
                isLoading: false,
                machines: action.payload
            }
        case MachinesActionTypes.SET_MACHINES_WITH_PAGINATION_IS_LOADING:
            return {
                ...state,
                isLoading: true
            }
        case MachinesActionTypes.SET_MACHINES_WITH_PAGINATION_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload
            }
        case MachinesActionTypes.SET_MACHINES_WITH_PAGINATION_SUCCESS:
            return {
                ...state,
                isLoading: false,
                machines: action.payload
            }
        case MachinesActionTypes.UPDATE_MACHINE_IS_LOADING:
            return {
                ...state,
                isLoading: true
            }
        case MachinesActionTypes.UPDATE_MACHINE_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload
            }
        case MachinesActionTypes.UPDATE_MACHINE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                machine: action.payload
            }
        case MachinesActionTypes.DELETE_MACHINE_IS_LOADING:
            return {
                ...state,
                isLoading: true
            }
        case MachinesActionTypes.DELETE_MACHINE_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload
            }
        case MachinesActionTypes.DELETE_MACHINE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                machine: action.payload
            }
        case MachinesActionTypes.SET_ALL_USERS_MACHINES_IS_LOADING:
            return {
                ...state,
                isLoading: true
            }
        case MachinesActionTypes.SET_ALL_USERS_MACHINES_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload
            }
        case MachinesActionTypes.SET_ALL_USERS_MACHINES_SUCCESS:
            return {
                ...state,
                isLoading: false,
                machines: action.payload
            }
        case MachinesActionTypes.SET_AVAILABLE_MACHINES_IS_LOADING:
            return {
                ...state,
                isLoading: true
            }
        case MachinesActionTypes.SET_AVAILABLE_MACHINES_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload
            }
        case MachinesActionTypes.SET_AVAILABLE_MACHINES_SUCCESS:
            return {
                ...state,
                isLoading: false,
                machine: action.payload
            }
        case MachinesActionTypes.SET_ASSIGN_MACHINE_IS_LOADING:
            return {
                ...state,
                isLoading: true
            }
        case MachinesActionTypes.SET_ASSIGN_MACHINE_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload
            }
        case MachinesActionTypes.SET_ASSIGN_MACHINE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                machine: action.payload
            }
        case MachinesActionTypes.SET_UNASSIGN_MACHINE_IS_LOADING:
            return {
                ...state,
                isLoading: true
            }
        case MachinesActionTypes.SET_UNASSIGN_MACHINE_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload
            }
        case MachinesActionTypes.SET_UNASSIGN_MACHINE_SUCCESS:
            return {
                ...state,
                isLoading: false,
            }
        case MachinesActionTypes.SET_SELECT_MACHINE_TYPE_IS_LOADING:
            return {
                ...state,
                isLoading: true
            }
        case MachinesActionTypes.SET_SELECT_MACHINE_TYPE_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload
            }
        case MachinesActionTypes.SET_SELECT_MACHINE_TYPE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                machine: action.payload
            }
        case MachinesActionTypes.GET_MACHINE_TYPE_IS_LOADING:
            return {
                ...state,
                isLoading: true
            }
        case MachinesActionTypes.GET_MACHINE_TYPE_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload
            }
        case MachinesActionTypes.GET_MACHINE_TYPE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                machine: action.payload
            }
        case MachinesActionTypes.SET_MACHINE_ON_CHANGE_IS_LOADING:
            return {
                ...state,
                isLoading: true
            }
        case MachinesActionTypes.SET_MACHINE_ON_CHANGE_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload
            }
        case MachinesActionTypes.SET_MACHINE_ON_CHANGE_SUCCESS:            
            return {
                ...state,
                isLoading: false,
                machines: action.payload
            }
        default:
            return state;
    }
}
export default machinesReducers;