import * as RNLocalize from 'react-native-localize';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './en/en.json'

export const resources = {
    en: { translation: en },
};

const lng = RNLocalize.getLocales()[0].languageCode;

i18n.use(initReactI18next).init({
    compatibilityJSON: 'v3',
    fallbackLng: 'en',
    supportedLngs: ['en'],
    debug: false,
    ns: ['translation'],
    defaultNS: 'translation',
    lng,
    resources,
    returnNull: false,
    interpolation: {
        escapeValue: false,
    },
});

export default i18n;