import { AuthResponse } from "../../services/api/api.interfaces";

export enum ActionTypes {
  SET_AUTH_IS_LOADING = 'SET_AUTH_IS_LOADING',
  SET_AUTH_FAILURE = 'SET_AUTH_FAILURE',
  SET_AUTH_TOKEN = 'SET_AUTH_TOKEN',
  SET_AUTH_SUCCESS = 'SET_AUTH_SUCCESS',
  SET_FORGOTPASSWORD_SUCCESS = 'SET_FORGOTPASSWORD_SUCCESS',
  SET_RESETPASSWORD_SUCCESS = 'SET_RESETPASSWORD_SUCCESS',
  SET_LOGOUT_SUCCESS = 'SET_LOGOUT_SUCCESS',
  SET_EMAILCONFIRMED_SUCCESS = 'SET_EMAILCONFIRMED_SUCCESS',
  SET_RESENDMAIL_SUCCESS = 'SET_RESENDMAIL_SUCCESS'
}

export const setAuthIsLoading = () => ({
  type: ActionTypes.SET_AUTH_IS_LOADING,
});

export const setAuthFailure = (error: string) => ({
  type: ActionTypes.SET_AUTH_FAILURE,
  payload: error,
});

export const setAuthToken = (data: string) => ({
  type: ActionTypes.SET_AUTH_TOKEN,
  payload: data,
});

export const setAuthSuccess = (data: AuthResponse) => ({
  type: ActionTypes.SET_AUTH_SUCCESS,
  payload: data,
});

export const setForgotPasswordSuccess = () => ({
    type: ActionTypes.SET_FORGOTPASSWORD_SUCCESS
});

export const setResetPasswordSuccess = (data: string) => {
  return {
    type: ActionTypes.SET_RESETPASSWORD_SUCCESS,
    payload: data,    
  };
};

export const setLogoutSuccess = () => ({
  type: ActionTypes.SET_LOGOUT_SUCCESS,
});

export const setEmailConfirmedSuccess = (data: string) => ({
  type: ActionTypes.SET_EMAILCONFIRMED_SUCCESS,
  payload: data,    
})

export const setResendMailSuccess = (data: string) => ({
  type: ActionTypes.SET_RESENDMAIL_SUCCESS,
  payload: data,
});

interface SetAuthIsLoadingAction {
  type: ActionTypes.SET_AUTH_IS_LOADING;
}

interface setAuthFailureAction {
  type: ActionTypes.SET_AUTH_FAILURE;
  payload: string;
}

interface setAuthTokenAction {
  type: ActionTypes.SET_AUTH_TOKEN;
  payload: string;
}

interface SetAuthSuccessAction {
  type: ActionTypes.SET_AUTH_SUCCESS;
  payload: AuthResponse;
}

interface SetForgotPasswordSuccessAction {
  type: ActionTypes.SET_FORGOTPASSWORD_SUCCESS;
}

interface SetResetPasswordSuccessAction {
  type: ActionTypes.SET_RESETPASSWORD_SUCCESS;
  payload: string,
}

interface SetLogoutSuccessAction {
  type: ActionTypes.SET_LOGOUT_SUCCESS;
}

interface SetEmailConfirmedSuccessAction {
  type: ActionTypes.SET_EMAILCONFIRMED_SUCCESS;
  payload: string,
}

interface SetResendMailSuccessAction {
  type: ActionTypes.SET_RESENDMAIL_SUCCESS;
  payload: string,
}

export type AuthActionTypes =
  | SetAuthIsLoadingAction
  | setAuthFailureAction
  | setAuthTokenAction
  | SetAuthSuccessAction
  | SetForgotPasswordSuccessAction
  | SetResetPasswordSuccessAction
  | SetLogoutSuccessAction
  | SetEmailConfirmedSuccessAction
  | SetResendMailSuccessAction;
