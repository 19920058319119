import { useEffect } from "react";
import store from "./store";
import { checkIsAuthenticated } from "./redux/auth/auth.thunk";
import StackNavigation from "./navigation/StackNavigation";

const App = () => {
  
  useEffect(() => {
    store.dispatch(checkIsAuthenticated())
  }, []);

  return (
    <>
      <StackNavigation />
    </>
  );
}

export default App;
