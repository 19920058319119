export const apiEndopints = {
  auth: {
    login: '/auth/login',
    logout: '/auth/logout',
    forgotPassword: '/auth/forgot-password',
    resetPassword: '/auth/reset-password',
    confirmEmail: '/auth/confirm-email',
    resendMail: '/auth/resend-mail',
  },
  users:{
    createServicers: '/users',
    allServisers: '/users?role=2&limit=5&offset=0',
    updateServicer: '/users/',
    deleteServicer: '/users/',
    searchServicer: '/users?role=2',
    paginationServicer: '/users?role=2',
    createClients: '/users',
    allClients: '/users?role=3&limit=5&offset=0',
    searchClients: '/users?role=3',
    paginationClient: '/users?role=3',
    updateClient: '/users/',
    deleteClient: '/users/',
    //location: '/users/locations'
    singleUser: '/users/',
    assignMachine: '/users/assign-machine/',
    unAssignMachine: '/users/unassign-machine/'
  },
  inventory: {
    createProducts: '/products',
    allProducts: '/products?limit=5&offset=0',
    paginationProduct: '/products',
    updateProduct: '/products/',
    deleteProduct: '/products/',
    searchProducts: '/products',
    product: '/products/'
  },
  machines: {
    createMachines: '/machines',
    allMachines: '/machines?limit=5&offset=0',
    paginationMachines: '/machines',
    updateMachine: '/machines/',
    deleteMachine: '/machines/',
    usersMachines: '/machines/',
    availableMachines: '/machines/available',
    searchMachines: '/machines',
  }
};
