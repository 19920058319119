import { AxiosError } from "axios";
import { IMachineType, IMachines } from "../../pages/Machines/Machine.type";

export enum MachinesActionTypes {
    CREATE_MACHINE_IS_LOADING = 'CREATE_MACHINE_IS_LOADING',
    CREATE_MACHINE_FAILURE = 'CREATE_MACHINE_FAILURE',
    CREATE_MACHINE_SUCCESS = 'CREATE_MACHINE_SUCCESS',
    SET_ALL_MACHINES_IS_LOADING = 'SET_ALL_MACHINES_IS_LOADING',
    SET_ALL_MACHINES_FAILURE = 'SET_ALL_MACHINES_FAILURE',
    SET_ALL_MACHINES_SUCCESS = 'SET_ALL_MACHINES_SUCCESS',
    SET_MACHINES_WITH_PAGINATION_IS_LOADING = 'SET_MACHINES_WITH_PAGINATION_IS_LOADING',
    SET_MACHINES_WITH_PAGINATION_FAILURE = 'SET_MACHINES_WITH_PAGINATION_FAILURE',
    SET_MACHINES_WITH_PAGINATION_SUCCESS = 'SET_MACHINES_WITH_PAGINATION_SUCCESS',
    UPDATE_MACHINE_IS_LOADING = 'UPDATE_MACHINE_IS_LOADING',
    UPDATE_MACHINE_FAILURE = 'UPDATE_MACHINE_FAILURE',
    UPDATE_MACHINE_SUCCESS = 'UPDATE_MACHINE_SUCCESS',
    DELETE_MACHINE_IS_LOADING = 'DELETE_MACHINE_IS_LOADING',
    DELETE_MACHINE_FAILURE = 'DELETE_MACHINE_FAILURE',
    DELETE_MACHINE_SUCCESS = 'DELETE_MACHINE_SUCCESS',
    SET_ALL_USERS_MACHINES_IS_LOADING = 'SET_ALL_USERS_MACHINES_IS_LOADING',
    SET_ALL_USERS_MACHINES_FAILURE = 'SET_ALL_USERS_MACHINES_FAILURE',
    SET_ALL_USERS_MACHINES_SUCCESS = 'SET_ALL_USERS_MACHINES_SUCCESS',
    SET_AVAILABLE_MACHINES_IS_LOADING = 'SET_AVAILABLE_MACHINES_IS_LOADING',
    SET_AVAILABLE_MACHINES_FAILURE = 'SET_AVAILABLE_MACHINES_FAILURE',
    SET_AVAILABLE_MACHINES_SUCCESS = 'SET_AVAILABLE_MACHINES_SUCCESS',
    SET_ASSIGN_MACHINE_IS_LOADING = 'SET_ASSIGN_MACHINE_IS_LOADING',
    SET_ASSIGN_MACHINE_FAILURE = 'SET_ASSIGN_MACHINE_FAILURE',
    SET_ASSIGN_MACHINE_SUCCESS = 'SET_ASSIGN_MACHINE_SUCCES',
    SET_UNASSIGN_MACHINE_IS_LOADING = 'SET_UNASSIGN_MACHINE_IS_LOADING',
    SET_UNASSIGN_MACHINE_FAILURE = 'SET_UNASSIGN_MACHINE_FAILURE',
    SET_UNASSIGN_MACHINE_SUCCESS = 'SET_UNASSIGN_MACHINE_SUCCES',
    SET_SELECT_MACHINE_TYPE_IS_LOADING = 'SET_SELECT_MACHINE_TYPE_IS_LOADING',
    SET_SELECT_MACHINE_TYPE_FAILURE = 'SET_SELECT_MACHINE_TYPE_FAILURE',
    SET_SELECT_MACHINE_TYPE_SUCCESS = 'SET_SELECT_MACHINE_TYPE_SUCCESS',
    GET_MACHINE_TYPE_IS_LOADING = 'GET_MACHINE_TYPE_IS_LOADING',
    GET_MACHINE_TYPE_FAILURE = 'GET_MACHINE_TYPE_FAILURE',
    GET_MACHINE_TYPE_SUCCESS = 'GET_MACHINE_TYPE_SUCCESS',
    SET_MACHINE_ON_CHANGE_IS_LOADING = 'SET_MACHINE_ON_CHANGE_IS_LOADING',
    SET_MACHINE_ON_CHANGE_SUCCESS = 'SET_MACHINE_ON_CHANGE_SUCCESS',
    SET_MACHINE_ON_CHANGE_FAILURE = 'SET_MACHINE_ON_CHANGE_FAILURE'
}

export const setCreateMachineIsLoading = () => ({
    type: MachinesActionTypes.CREATE_MACHINE_IS_LOADING
});
export const setCreateMachineFailure = (error: string) => ({
    type: MachinesActionTypes.CREATE_MACHINE_FAILURE,
    payload: error
});
export const setCreateMachineSuccess = (data: IMachines) => ({
    type: MachinesActionTypes.CREATE_MACHINE_SUCCESS,
    payload: data
});
export const setAllMachinesIsLoading = () => ({
    type: MachinesActionTypes.SET_ALL_MACHINES_IS_LOADING
});
export const setAllMachinesFailure = (error: AxiosError) => ({
    type:MachinesActionTypes.SET_ALL_MACHINES_FAILURE,
    payload: error
});
export const setAllMachinesSuccess = (data: IMachines[]) => ({
    type: MachinesActionTypes.SET_ALL_MACHINES_SUCCESS,
    payload: data
});
export const setMachinesWithPaginationIsLoading = () => ({
    type: MachinesActionTypes.SET_MACHINES_WITH_PAGINATION_IS_LOADING
});
export const setMachinesWithPaginationFailure = (error: AxiosError) => ({
    type: MachinesActionTypes.SET_MACHINES_WITH_PAGINATION_FAILURE,
    payload: error
});
export const setMachinesWithPaginationSuccess = (data: IMachines[]) => ({
    type: MachinesActionTypes.SET_MACHINES_WITH_PAGINATION_SUCCESS,
    payload: data
});
export const setUpdateMachineIsLoading = () => ({
    type: MachinesActionTypes.UPDATE_MACHINE_IS_LOADING
});
export const setUpdateMachineFailure = (error: AxiosError) => ({
    type: MachinesActionTypes.UPDATE_MACHINE_FAILURE,
    payload: error
});
export const setUpdateMachineSuccess = (data: IMachines) => ({
    type: MachinesActionTypes.UPDATE_MACHINE_SUCCESS,
    payload: data
});
export const setDeleteMachineIsLoading = () => ({
    type: MachinesActionTypes.DELETE_MACHINE_IS_LOADING
});
export const setDeleteMachineFailure = (error: AxiosError) => ({
    type: MachinesActionTypes.DELETE_MACHINE_FAILURE,
    payload: error
});
export const setDeleteMachineSuccess = (data: IMachines) => ({
    type: MachinesActionTypes.DELETE_MACHINE_SUCCESS,
    payload: data
});
export const setAllUsersMachinesIsLoading = () => ({
    type: MachinesActionTypes.SET_ALL_USERS_MACHINES_IS_LOADING
});
export const setAllUsersMachinesFailure = (error: AxiosError) => ({
    type: MachinesActionTypes.SET_ALL_USERS_MACHINES_FAILURE,
    payload: error
});
export const setAllUsersMachinesSuccess = (data: IMachines[]) => ({
    type: MachinesActionTypes.SET_ALL_USERS_MACHINES_SUCCESS,
    payload: data
});
export const setAvailableMachinesIsLoading = () => ({
    type: MachinesActionTypes.SET_AVAILABLE_MACHINES_IS_LOADING
});
export const setAvailableMachinesFailure = (error: AxiosError) => ({
    type: MachinesActionTypes.SET_AVAILABLE_MACHINES_FAILURE,
    payload: error
});
export const setAvailableMachinesSuccess = (data: IMachines) => ({
    type: MachinesActionTypes.SET_AVAILABLE_MACHINES_SUCCESS,
    payload: data
});
export const setAssignMachineIsLoading = () => ({
    type: MachinesActionTypes.SET_ASSIGN_MACHINE_IS_LOADING
});
export const setAssignMachineFailure = (error: AxiosError) => ({
    type: MachinesActionTypes.SET_ASSIGN_MACHINE_FAILURE,
    payload: error
});
export const setAssignMachineSuccess = (data: IMachines) => ({
    type: MachinesActionTypes.SET_ASSIGN_MACHINE_SUCCESS,
    payload: data
});
export const setUnAssignMachineIsLoading = () => ({
    type: MachinesActionTypes.SET_UNASSIGN_MACHINE_IS_LOADING
});
export const setUnAssignMachineFailure = (error: AxiosError) => ({
    type: MachinesActionTypes.SET_UNASSIGN_MACHINE_FAILURE,
    payload: error
});
export const setUnAssignMachineSuccess = () => ({
    type: MachinesActionTypes.SET_UNASSIGN_MACHINE_SUCCESS,
});
export const setSelectMachineTypeIsLoading = () => ({
    type: MachinesActionTypes.SET_SELECT_MACHINE_TYPE_IS_LOADING,
});
export const setSelectMachineTypeFailure = (error: string) => ({
    type: MachinesActionTypes.SET_SELECT_MACHINE_TYPE_FAILURE,
    payload: error
});
export const setSelectMachineTypeSuccess = (data: IMachineType[]) => ({
    type: MachinesActionTypes.SET_SELECT_MACHINE_TYPE_SUCCESS,
    payload: data
});
export const setGetMachineTypeIsLoading = () => ({
    type: MachinesActionTypes.GET_MACHINE_TYPE_IS_LOADING
});
export const setGetMachineTypeFailure = (error: string) => ({
    type: MachinesActionTypes.GET_MACHINE_TYPE_FAILURE,
    payload: error
});
export const setGetMachineTypeSuccess = (data: IMachineType[]) => ({
    type: MachinesActionTypes.GET_MACHINE_TYPE_SUCCESS,
    payload: data
});
export const setSearchMachinesIsLoading = () => ({
    type: MachinesActionTypes.SET_MACHINE_ON_CHANGE_IS_LOADING
});

export const setSearchMachinesFailure = (error: string) => ({
    type: MachinesActionTypes.SET_MACHINE_ON_CHANGE_FAILURE,
    payload: error
});

export const setSearchMachinesSuccess = (data: IMachines[]) => ({
    type: MachinesActionTypes.SET_MACHINE_ON_CHANGE_SUCCESS,
    payload: data
});

interface SetCreateMachineIsLoading {
    type: MachinesActionTypes.CREATE_MACHINE_IS_LOADING
}
interface SetCreateMachineFailure {
    type: MachinesActionTypes.CREATE_MACHINE_FAILURE,
    payload: string
}
interface SetCreateMachineSuccess {
    type: MachinesActionTypes.CREATE_MACHINE_SUCCESS,
    payload: IMachines
}
interface SetAllMachinesIsLoading {
    type: MachinesActionTypes.SET_ALL_MACHINES_IS_LOADING
}
interface SetAllMachinesFailure {
    type: MachinesActionTypes.SET_ALL_MACHINES_FAILURE,
    payload: string
}
interface SetAllMachinesSuccess {
    type: MachinesActionTypes.SET_ALL_MACHINES_SUCCESS,
    payload: IMachines[]
}
interface SetMachinesWithPaginationIsLoading {
    type: MachinesActionTypes.SET_MACHINES_WITH_PAGINATION_IS_LOADING
}
interface SetMachinesWithPaginationFailure {
    type: MachinesActionTypes.SET_MACHINES_WITH_PAGINATION_FAILURE,
    payload: string
}
interface SetMachinesWithPaginationSuccess {
    type: MachinesActionTypes.SET_MACHINES_WITH_PAGINATION_SUCCESS,
    payload: IMachines[]
}
interface SetUpdateMachineIsLoading {
    type: MachinesActionTypes.UPDATE_MACHINE_IS_LOADING
}
interface SetUpdateMachineFailure {
    type: MachinesActionTypes.UPDATE_MACHINE_FAILURE,
    payload: string
}
interface SetUpdateMachineSuccess {
    type: MachinesActionTypes.UPDATE_MACHINE_SUCCESS,
    payload: IMachines
}
interface SetDeleteMachineIsLoading {
    type: MachinesActionTypes.DELETE_MACHINE_IS_LOADING
}
interface SetDeleteMachineFailure {
    type: MachinesActionTypes.DELETE_MACHINE_FAILURE,
    payload: string
}
interface SetDeleteMachineSuccess {
    type: MachinesActionTypes.DELETE_MACHINE_SUCCESS,
    payload: IMachines
}
interface SetAllUsersMachinesIsLoading {
    type: MachinesActionTypes.SET_ALL_USERS_MACHINES_IS_LOADING
}
interface SetAllUsersMachinesFailure {
    type: MachinesActionTypes.SET_ALL_USERS_MACHINES_FAILURE,
    payload: string
}
interface SetAllUsersMachinesSuccess {
    type: MachinesActionTypes.SET_ALL_USERS_MACHINES_SUCCESS,
    payload: IMachines[]
}
interface SetAvailableMachinesIsLoading {
    type: MachinesActionTypes.SET_AVAILABLE_MACHINES_IS_LOADING
}
interface SetAvailableMachinesFailure {
    type: MachinesActionTypes.SET_AVAILABLE_MACHINES_FAILURE,
    payload: string
}
interface SetAvailableMachinesSuccess {
    type: MachinesActionTypes.SET_AVAILABLE_MACHINES_SUCCESS,
    payload: IMachines
}
interface SetAssignMachineIsLoading {
    type: MachinesActionTypes.SET_ASSIGN_MACHINE_IS_LOADING
}
interface SetAssignMachineFailure {
    type: MachinesActionTypes.SET_ASSIGN_MACHINE_FAILURE,
    payload: string
}
interface SetAssignMachineSuccess {
    type: MachinesActionTypes.SET_ASSIGN_MACHINE_SUCCESS,
    payload: IMachines
}
interface SetUnAssignMachineIsLoading {
    type: MachinesActionTypes.SET_UNASSIGN_MACHINE_IS_LOADING
}
interface SetUnAssignMachineFailure {
    type: MachinesActionTypes.SET_UNASSIGN_MACHINE_FAILURE,
    payload: string
}
interface SetUnAssignMachineSuccess {
    type: MachinesActionTypes.SET_UNASSIGN_MACHINE_SUCCESS,
}
interface SetSelectMachineTypeIsLoading {
    type: MachinesActionTypes.SET_SELECT_MACHINE_TYPE_IS_LOADING,
}
interface SetSelectMachineTypeFailure {
    type: MachinesActionTypes.SET_SELECT_MACHINE_TYPE_FAILURE,
    payload: string
}
interface SetSelectMachineTypeSuccess {
    type: MachinesActionTypes.SET_SELECT_MACHINE_TYPE_SUCCESS,
    payload: IMachineType[]
}
interface SetGetMachineTypeIsLoading {
    type: MachinesActionTypes.GET_MACHINE_TYPE_IS_LOADING
}
interface SetGetMachineTypeFailure {
    type: MachinesActionTypes.GET_MACHINE_TYPE_FAILURE,
    payload: string
}
interface SetGetMachineTypeSuccess {
    type: MachinesActionTypes.GET_MACHINE_TYPE_SUCCESS,
    payload: IMachineType[]
}
interface SetSearchMachinesIsLoading {
    type: MachinesActionTypes.SET_MACHINE_ON_CHANGE_IS_LOADING
}

interface SetSearchMachinesFailure {
    type: MachinesActionTypes.SET_MACHINE_ON_CHANGE_FAILURE,
    payload: string
}

interface SetSearchMachinesSuccess {
    type: MachinesActionTypes.SET_MACHINE_ON_CHANGE_SUCCESS,
    payload: IMachines[]
}

export type MachineActionTypes =
    | SetCreateMachineIsLoading
    | SetCreateMachineFailure
    | SetCreateMachineSuccess
    | SetAllMachinesIsLoading
    | SetAllMachinesFailure
    | SetAllMachinesSuccess
    | SetMachinesWithPaginationIsLoading
    | SetMachinesWithPaginationFailure
    | SetMachinesWithPaginationSuccess
    | SetUpdateMachineIsLoading
    | SetUpdateMachineFailure
    | SetUpdateMachineSuccess
    | SetDeleteMachineIsLoading
    | SetDeleteMachineFailure
    | SetDeleteMachineSuccess
    | SetAllUsersMachinesIsLoading
    | SetAllUsersMachinesFailure
    | SetAllUsersMachinesSuccess
    | SetAvailableMachinesIsLoading
    | SetAvailableMachinesFailure
    | SetAvailableMachinesSuccess
    | SetAssignMachineIsLoading
    | SetAssignMachineFailure
    | SetAssignMachineSuccess
    | SetUnAssignMachineIsLoading
    | SetUnAssignMachineFailure
    | SetUnAssignMachineSuccess
    | SetSelectMachineTypeIsLoading
    | SetSelectMachineTypeFailure
    | SetSelectMachineTypeSuccess
    | SetGetMachineTypeIsLoading
    | SetGetMachineTypeFailure
    | SetGetMachineTypeSuccess
    | SetSearchMachinesIsLoading
    | SetSearchMachinesFailure
    | SetSearchMachinesSuccess;
