import { createContext, useEffect, useState } from "react";
import '../translates/i18n';
import { useTranslation } from "react-i18next";

import translationSource from '../translates/en/en.json';

export type ITranslations = typeof translationSource.vendify;

interface ITranslationContext<T> {
    t: T | null;
}

export const TranslationContext = createContext<ITranslationContext<any>>({t: null});

export const TranslationContextProvider: React.FC<{children: React.ReactNode}> = ({ children }: {children: React.ReactNode}) => {
    const [translations, setTranslations] = useState<any>(null);

    const { t } = useTranslation();

    useEffect(() => {
        const phrases: any = t('vendify', { returnObjects: true });

        setTranslations(phrases);
    }, [t]);

    if (translations) {
        return (
            <TranslationContext.Provider value={{ t: translations }}>
                {children}
            </TranslationContext.Provider>
        );
    }

    return <></>;
};