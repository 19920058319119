import { apiEndopints } from '../../consts/apiEndpoints';
import { IConfirmEmailParams } from '../../pages/EmailConfirmationPage/EmailConfirmation.type';
import { IForgotPasswordParams } from '../../pages/ForgotPasswordPage/ForgotPassword.type';
import { IProductParams, IProductUpdateParams } from '../../pages/Inventory/Product.type';
import { ILoginParams } from '../../pages/LoginPage/Login.type';
import { IAssignedMachines, IMachineParams, IMachineType, IMachineUpdateParams } from '../../pages/Machines/Machine.type';
import { IResetPasswordParams } from '../../pages/ResetPasswordPage/ResetPassword.type';
import { IClientParams, IClientUpdateParams } from '../../pages/Users/Clients/Clients.type';
import { IServicerParams, IServicerUpdateParams } from '../../pages/Users/Servicers/Servicers.type';
import { request, requestWithoutToken } from '../interceptors/httpInterceptor';

export const requestLogin = async (reqBody: ILoginParams) => {
  return await requestWithoutToken('POST', apiEndopints.auth.login, reqBody);
};
export const requestLogOut = async () => {
  return await request('POST', apiEndopints.auth.logout);
};
export const requestForgotPassword = async (reqBody: IForgotPasswordParams) => {
  return await requestWithoutToken('POST', apiEndopints.auth.forgotPassword, reqBody);
};
export const requestResetPassword = async (reqBody: IResetPasswordParams, token: string) => {
  return await request('PUT', `${apiEndopints.auth.resetPassword}?token=${token}`, reqBody);
};
export const requestEmailConfirmation = async (token: string) => {
  return await request('GET', `${apiEndopints.auth.confirmEmail}?token=${token}`);
}
export const requestResendMail = async (reqBody: IConfirmEmailParams) => {
  return await requestWithoutToken('POST', apiEndopints.auth.resendMail, reqBody);
}
export const requestAllServicers = async () => {
  return await request('GET', `${apiEndopints.users.allServisers}`)
}
export const requestCreateServicers = async (reqBody: IServicerParams) => {
  return await request('POST', apiEndopints.users.createServicers, reqBody)
}
export const requestUpdateServicers = async (reqBody: IServicerUpdateParams, servicerId: number) => {
  return await request('PATCH', `${apiEndopints.users.updateServicer}${servicerId}`, reqBody)
}
export const requestDeleteServicers = async (servicerId: number) => {
  return await request('DELETE', `${apiEndopints.users.deleteServicer}${servicerId}`)
}
export const requestSearchServicers = async (query: string) => {
  return await request('GET', `${apiEndopints.users.searchServicer}&searchText=${query}&limit=5`)
}
export const requestServicersWithPagination = async (offset: number, limit: number, query?: string) => {
  return await request('GET', `${apiEndopints.users.paginationServicer}&offset=${offset}&limit=${limit}&searchText=${query}`)
}
export const requestCreateProducts = async (reqBody: IProductParams) => {
  return await request('POST', apiEndopints.inventory.createProducts, reqBody)
}
export const requestAllProducts = async () => {
  return await request('GET', `${apiEndopints.inventory.allProducts}`)
}
export const requestProductsWithPagination = async (offset: number, limit: number, query?: string) => {
  return await request('GET', `${apiEndopints.inventory.paginationProduct}?offset=${offset}&limit=${limit}&searchText=${query}`)
}
export const requestUpdateProduct = async (reqBody: IProductUpdateParams, productId: number) => {
  return await request('PATCH', `${apiEndopints.inventory.updateProduct}${productId}`, reqBody)
}
export const requestDeleteProduct = async (productId: number) => {
  return await request('DELETE', `${apiEndopints.inventory.deleteProduct}${productId}`)
}
export const requestSearchProducts = async (query: string) => {
  return await request('GET', `${apiEndopints.inventory.searchProducts}?searchText=${query}&limit=5`)
}
export const requestCreateClients = async (reqBody: IClientParams) => {
  return await request('POST', apiEndopints.users.createClients, reqBody)
}
export const requestAllClients = async () => {
  return await request('GET', `${apiEndopints.users.allClients}`)
}
export const requestSearchClients = async (query: string) => {
  return await request('GET', `${apiEndopints.users.searchClients}&searchText=${query}&limit=5`)
}
export const requestClientsWithPagination = async (offset: number, limit: number, query?: string) => {
  return await request('GET', `${apiEndopints.users.paginationClient}&offset=${offset}&limit=${limit}&searchText=${query}`)
}
export const requestUpdateClient = async (reqBody: IClientUpdateParams, clientId: number) => {
  return await request('PATCH', `${apiEndopints.users.updateClient}${clientId}`, reqBody)
}
export const requestDeleteClient = async (clientId: number) => {
  return await request('DELETE', `${apiEndopints.users.deleteClient}${clientId}`)
}
// export const requestLocation = async () => {
//   return await request('GET', apiEndopints.users.location)
// }
export const requestProductHistoryById = async (productId: string) => {
  return await request('GET', `${apiEndopints.inventory.product}${productId}`)
}
export const requestClientById = async (clientId: string) => {
  return await request ('GET', `${apiEndopints.users.singleUser}${clientId}`);
}
export const requestCreateMachines = async (reqBody: IMachineParams) => {
  return await request ('POST', apiEndopints.machines.createMachines, reqBody);
}
export const requestAllMachines = async (machineType: string) => {
  return await request('GET', `${apiEndopints.machines.allMachines}&machineType=${machineType}`);
}
export const requestMachinesWithPagination = async (offset: number, limit: number, machineType: string, query?: string) => {
  return await request('GET', `${apiEndopints.machines.paginationMachines}?offset=${offset}&limit=${limit}&machineType=${machineType}&searchText=${query}`)
}
export const requestUpdateMachine = async (reqBody: IMachineUpdateParams, machineId: number) => {
  return await request('PATCH', `${apiEndopints.machines.updateMachine}${machineId}`, reqBody)
}
export const requestDeleteMachine = async (machineId: number) => {
  return await request('DELETE', `${apiEndopints.machines.deleteMachine}${machineId}`)
}
export const requestProductById = async (productId: number) => {
  return await request('GET', `${apiEndopints.inventory.product}${productId}`)
}
export const requestUsersMachinesById = async (userId: number) => {
  return await request('GET', `${apiEndopints.machines.usersMachines}?userId=${userId}`)
}
export const requestAvailableMachines = async () => {
  return await request('GET', apiEndopints.machines.availableMachines)
}
export const requestAssignMachine = async (userId: number, reqBody: IAssignedMachines) => {
  return await request('POST', `${apiEndopints.users.assignMachine}${userId}`, reqBody)
}
export const requestUnAssignMachine = async (userId: number, reqBody: IAssignedMachines) => {
  return await request('POST', `${apiEndopints.users.unAssignMachine}${userId}`, reqBody)
}
export const requestSelectMachineType = async (userId: number, reqBody: IMachineType) => {
  return await request('PATCH', `/users/${userId}/preferences/machine-types`, reqBody)
}
export const requestGetMachineTypes = async (userId: number) => {
  return await request('GET', `/users/${userId}/preferences/machine-types`)
}
export const requestSearchMachines = async (machineType: string, query: string) => {
  return await request('GET', `${apiEndopints.machines.searchMachines}?machineType=${machineType}&searchText=${query}&limit=5`)
}