import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { TranslationContextProvider } from './context/TranslationContextProvider';
import { Provider as ReduxProvider } from 'react-redux';
import './translates/i18n';
import store from './store';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { PrimeReactProvider } from 'primereact/api';
import ToastProvider  from './context/ToastContextProvider';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <ReduxProvider store={store}>
      <TranslationContextProvider>
        <PrimeReactProvider>
          <ToastProvider>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </ToastProvider>
        </PrimeReactProvider>
      </TranslationContextProvider>
    </ReduxProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
