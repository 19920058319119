import { useSelector } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import { RootState } from '../store';
import { NavigatorStackPages } from '../enum/NavigatorStack';
import { Suspense, lazy } from 'react';
import { PageLoading } from '../components/Loader/PageLoading';

const PageNotFound = lazy(() => import('../pages/PageNotFound/PageNotFound'));
const RootLayout = lazy(() => import('../components/Sidebar/RootLayout'));
const Dashboard = lazy(() => import('../pages/Dashboard/Dashboard'));
const Users = lazy(() => import('../pages/Users/Users'));
const Inventory = lazy(() => import('../pages/Inventory/Inventory'));
const Machines = lazy(() => import('../pages/Machines/Machines'));
const Tasks = lazy(() => import('../pages/Tasks/Tasks'));
const Finances = lazy(() => import('../pages/Finances/Finances'));
const Settings = lazy(() => import('../pages/Settings/Settings'));
const ForgotPasswordPage = lazy(() => import('../pages/ForgotPasswordPage/ForgotPasswordPage'));
const ConfirmationSentPage = lazy(() => import('../pages/ConfirmationSentPage/ConfirmationSentPage'));
const ResetPasswordPage = lazy(() => import('../pages/ResetPasswordPage/ResetPasswordPage'));
const EmailConfirmationPage = lazy(() => import('../pages/EmailConfirmationPage/EmailConfirmationPage'));
const ProductHistory = lazy(() => import('../pages/Inventory/ProductHistory/ProductHistory'));
const UsersMachines = lazy(() => import('../pages/Users/UserMachines/UsersMachines'));
const LoginPage = lazy(() => import('../pages/LoginPage/LoginPage'));

const StackNavigation = () => {
  const { isAuthenticated } = useSelector((state: RootState) => state.auth);
  return (
    <Suspense fallback={<PageLoading/>}>
      <Routes>
        {isAuthenticated ? (
          <>
            <Route path='/' element={<RootLayout />}>
              <Route index element={<Dashboard />} />
              <Route index path={NavigatorStackPages.Dashboard} element={<Dashboard />} />
              <Route path={NavigatorStackPages.Users} element={<Users />} />
              <Route path={NavigatorStackPages.Inventory} element={<Inventory />} />
              <Route path={NavigatorStackPages.Machines} element={<Machines />} />
              <Route path={NavigatorStackPages.Tasks} element={<Tasks />} />
              <Route path={NavigatorStackPages.Finances} element={<Finances />} />
              <Route path={NavigatorStackPages.Settings} element={<Settings />} />
              <Route path={NavigatorStackPages.ProductHistory} element={<ProductHistory />}>
                <Route path=':productId' element={<ProductHistory />} />
              </Route>
              <Route path={NavigatorStackPages.UsersMachines} element={<UsersMachines />}>
                <Route path=':userId' element={<UsersMachines />} />
              </Route>
            </Route>
            <Route path='*' element={<PageNotFound />} />
          </>
        ) : (
          <>
            <Route index element={<LoginPage />} />
            <Route path={NavigatorStackPages.Login} element={<LoginPage />} />
            <Route path={NavigatorStackPages.ForgotPassword} element={<ForgotPasswordPage />} />
            <Route path={NavigatorStackPages.ConfirmationSent} element={<ConfirmationSentPage />} />
            <Route path={NavigatorStackPages.ResetPassword} element={<ResetPasswordPage />} />
            <Route path='*' element={<PageNotFound />} />
          </>
        )}
        <Route path={NavigatorStackPages.EmailConfirmation} element={<EmailConfirmationPage />} />
      </Routes>
    </Suspense>
  );
};

export default StackNavigation;
