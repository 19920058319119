import { Dispatch } from 'redux';
import { ILoginParams } from '../../pages/LoginPage/Login.type';
import {
  requestEmailConfirmation,
  requestForgotPassword,
  requestLogOut,
  requestLogin,
  requestResendMail,
  requestResetPassword,
} from '../../services/api/api.service';
import {
  setAuthFailure,
  setAuthIsLoading,
  setAuthSuccess,
  setAuthToken,
  setEmailConfirmedSuccess,
  setForgotPasswordSuccess,
  setLogoutSuccess,
  setResendMailSuccess,
  setResetPasswordSuccess,
} from './auth.action';
import axios from 'axios';
import CookieService from '../../services/CookieService';
import { IForgotPasswordParams } from '../../pages/ForgotPasswordPage/ForgotPassword.type';
import { NavigatorStackPages } from '../../enum/NavigatorStack';
import { IResetPasswordParams } from '../../pages/ResetPasswordPage/ResetPassword.type';
import { IConfirmEmailParams } from '../../pages/EmailConfirmationPage/EmailConfirmation.type';
import { Role } from '../../enum/role';

export const login = (reqBody: ILoginParams) => async (dispatch: Dispatch) => {
  try {
    dispatch(setAuthIsLoading()); 
    const { rememberMe, ...requestData } = reqBody;
    const response = await requestLogin(requestData);    
    if (response.data.user.role === Role.ADMIN) {
      CookieService.set('access_token', response.data.access_token);
      if (rememberMe) {
        CookieService.set('RMBM', JSON.stringify(requestData));
      } else {
        CookieService.remove('RMBM');
      }
      dispatch(setAuthSuccess(response.data));
    } else {
      dispatch(setAuthFailure('This account does not have required permissions to login!'));
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch(setAuthFailure(message));
    }
  }
};

export const logout = () => async (dispatch: Dispatch) => {
  try {
    dispatch(setAuthIsLoading());
    await requestLogOut();
    CookieService.remove('access_token');
    dispatch(setLogoutSuccess());
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch(setAuthFailure(message));
    }
  }
};
export const checkIsAuthenticated = () => async (dispatch: Dispatch) => {
  try {
    dispatch(setAuthIsLoading());
    const tokens = CookieService.get('access_token');

    if (tokens) {
      dispatch(setAuthToken(tokens));
    } else {
      dispatch(setLogoutSuccess());
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch(setAuthFailure(message));
    }
  }
};

export const forgotPassword = (reqBody: IForgotPasswordParams, navigate: Function) => async (dispatch: Dispatch) => {
    try {
      dispatch(setAuthIsLoading());
      await requestForgotPassword(reqBody);
      dispatch(setForgotPasswordSuccess());
      navigate('/'+ NavigatorStackPages.ConfirmationSent, {
        state: { email: reqBody.email },
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        dispatch(setAuthFailure(message));
      }
    }
  };

  export const resetPassword = (reqBody: IResetPasswordParams, token: string) => async (dispatch: Dispatch) => {
    try {
      dispatch(setAuthIsLoading());
      const response = await requestResetPassword(reqBody, token);
      dispatch(setResetPasswordSuccess(response.data));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        dispatch(setAuthFailure(message));
      }
    }
  };

  export const confirmEmail = (token: string) => async (dispatch: Dispatch) => {
    try {
      dispatch(setAuthIsLoading());
      const response = await requestEmailConfirmation(token);
      dispatch(setEmailConfirmedSuccess(response.data.message));  
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        dispatch(setAuthFailure(message));
      }
    }
  }

  export const resendMail = (reqBody: IConfirmEmailParams) => async (dispatch: Dispatch) => {
    try {
      dispatch(setAuthIsLoading());
      const response = await requestResendMail(reqBody);
      dispatch(setResendMailSuccess(response.data.message)); 
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        dispatch(setAuthFailure(message));
      }
    }
  }
