import { IUser } from "../../services/api/api.interfaces";
import { ActionTypes, AuthActionTypes } from "./auth.action";

export interface State {
  user: IUser | null;
  tokens: string | undefined;
  isLoading: boolean;
  error: string | null;
  isAuthenticated: boolean;
  data: string | undefined;
}

const authInitialState: State = {
  user: null,
  tokens: undefined,
  isLoading: false,
  error: null,
  isAuthenticated: false,
  data: undefined,
};

const authReducers = (state = authInitialState, action: AuthActionTypes) => {
  switch (action.type) {
    case ActionTypes.SET_AUTH_IS_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case ActionTypes.SET_AUTH_SUCCESS:
      const { user, access_token } = action.payload;
      return {
        ...state,
        user,
        access_token,
        isLoading: false,
        isAuthenticated: true,
      };
    case ActionTypes.SET_AUTH_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        isAuthenticated: false,
      };
    case ActionTypes.SET_AUTH_TOKEN:
      return {
        ...state,
        tokens: action.payload,
        isAuthenticated: true
      };
    case ActionTypes.SET_FORGOTPASSWORD_SUCCESS:
       return {
        ...authInitialState,
       };
    case ActionTypes.SET_RESETPASSWORD_SUCCESS:      
    return {
      ...state,
      data: action.payload,
      isLoading: false,
    };
    case ActionTypes.SET_LOGOUT_SUCCESS:
      return {
        ...authInitialState,
      };
    case ActionTypes.SET_EMAILCONFIRMED_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
      }
    case ActionTypes.SET_RESENDMAIL_SUCCESS:      
      return {
        ...state,
        data: action.payload,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default authReducers;