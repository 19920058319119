import axios, { AxiosRequestConfig } from "axios";
import { TRequest } from "../api/api.interfaces";
import { DEVELOPMENT_BASE_URL } from "../../consts/http.consts";
import CookieService from "../CookieService";

const apiClient = axios.create({
  baseURL: DEVELOPMENT_BASE_URL,
  withCredentials: true,
  headers: {
    "Accept": "application/json",
    "Content-Type": "application/json"
  }
});

apiClient.interceptors.response.use(
  response => response,
  async error => {
    const originalRequest = error.config;
    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        await new Promise(resolve => setTimeout(resolve, 1000));
        const newAccessToken = CookieService.get('access_token');
        if (newAccessToken) {
          originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
          return apiClient(originalRequest);
        } else {
          throw new Error('New access token not found in cookies');
        }
      } catch (retryError) {
        throw retryError;
      }
    }
    return Promise.reject(error);
  }
);

const makeRequest = async (options: AxiosRequestConfig): Promise<any> => {
  try {
    const response = await apiClient(options);
    return response;
  } catch (error) {
    throw error;
  }
};

export const requestWithoutToken: TRequest = async (method, url, params = {}) => {
  const options: AxiosRequestConfig = {
    method,
    url,
    data: params
  };
  return makeRequest(options);
};

export const request: TRequest = async (method, url, params = {}) => {
  const accessToken = CookieService.get('access_token') as string;
  const options: AxiosRequestConfig = {
    method,
    url,
    data: params,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return makeRequest(options);
};
