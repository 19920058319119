import { IProducts } from "../../pages/Inventory/Product.type";
import { IProductsHistory } from "../../services/api/api.interfaces";
import { InventoryActionTypes, ProductsActionTypes } from "./products.action";

export interface State {
    products: IProducts[],
    product: IProducts | undefined,
    productsHistory: IProductsHistory[],
    isLoading: boolean,
    error: string | undefined;
}

const productsInitialState: State = {
    products: [],
    product: undefined,
    productsHistory: [],
    isLoading: false,
    error: undefined,
};

const productsReducers = (state = productsInitialState, action: ProductsActionTypes) => {
    switch (action.type) {
        case InventoryActionTypes.SET_ALL_PRODUCTS_IS_LOADING:
            return {
                ...state,
                isLoading: true,
            }
        case InventoryActionTypes.SET_ALL_PRODUCTS_IS_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload
            }
        case InventoryActionTypes.SET_ALL_PRODUCTS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                products: action.payload
            }
        case InventoryActionTypes.CREATE_PRODUCT_IS_LOADING:
            return {
                ...state,
                isLoading: true,
            };
        case InventoryActionTypes.CREATE_PRODUCT_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            };
        case InventoryActionTypes.CREATE_PRODUCT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                product: action.payload,
            };
        case InventoryActionTypes.SET_PRODUCT_WITH_PAGINATION_IS_LOADING:
            return {
                ...state,
                isLoading: true
            }
        case InventoryActionTypes.SET_PRODUCT_WITH_PAGINATION_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload
            }
        case InventoryActionTypes.SET_PRODUCT_WITH_PAGINATION_SUCCESS:
            return {
                ...state,
                isLoading: false,
                products: action.payload
            }
        case InventoryActionTypes.UPDATE_PRODUCT_IS_LOADING:
            return {
                ...state,
                isLoading: true
            }
        case InventoryActionTypes.UPDATE_PRODUCT_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload
            }
        case InventoryActionTypes.UPDATE_PRODUCT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                product: action.payload
            }
        case InventoryActionTypes.DELETE_PRODUCT_IS_LOADING:
            return {
                ...state,
                isLoading: true
            }
        case InventoryActionTypes.DELETE_PRODUCT_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload
            }
        case InventoryActionTypes.DELETE_PRODUCT_SUCCESS:
            return {
                ...state,
                isLoading: false
            }
        case InventoryActionTypes.SET_PRODUCT_ON_CHANGE_IS_LOADING:
            return {
                ...state,
                isLoading: true
            }
        case InventoryActionTypes.SET_PRODUCT_ON_CHANGE_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload
            }
        case InventoryActionTypes.SET_PRODUCT_ON_CHANGE_SUCCESS:            
            return {
                ...state,
                isLoading: false,
                products: action.payload
            }
        case InventoryActionTypes.SET_PRODUCTHISTORY_BY_ID_IS_LOADING:
            return {
                ...state,
                isLoading: true
            }
        case InventoryActionTypes.SET_PRODUCTHISTORY_BY_ID_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload
            }
        case InventoryActionTypes.SET_PRODUCTHISTORY_BY_ID_SUCCESS:            
            return {
                ...state,
                isLoading: false,
                productsHistory: action.payload
            }
        case InventoryActionTypes.SET_PRODUCT_BY_ID_IS_LOADING:
            return {
                ...state,
                isLoading: true
            }
        case InventoryActionTypes.SET_PRODUCT_BY_ID_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload
            }
        case InventoryActionTypes.SET_PRODUCT_BY_ID_SUCCESS:
            return {
                ...state,
                isLoading: false,
                product: action.payload
            }
        default:
            return state;
    }
};
export default productsReducers;