export enum ServicersActionTypes {
    SET_ALL_SERVICERS_IS_LOADING = 'SET_ALL_SERVICERS_IS_LOADING',
    SET_ALL_SERVICERS_SUCCESS = 'SET_ALL_SERVICERS_SUCCESS',
    SET_ALL_SERVICERS_FAILURE = 'SET_ALL_SERVICERS_FAILURE',
    SET_SERVICERS_WITH_PAGINATION_IS_LOADING = 'SET_SERVICERS_WITH_PAGINATION_IS_LOADING',
    SET_SERVICERS_WITH_PAGINATION_SUCCESS = 'SET_SERVICERS_WITH_PAGINATION_SUCCESS',
    SET_SERVICERS_WITH_PAGINATION_FAILURE = 'SET_SERVICERS_WITH_PAGINATION_FAILURE',
    SET_SERVICER_ON_CHANGE_IS_LOADING = 'SET_SERVICER_ON_CHANGE_IS_LOADING',
    SET_SERVICER_ON_CHANGE_SUCCESS = 'SET_SERVICER_ON_CHANGE_SUCCESS',
    SET_SERVICER_ON_CHANGE_FAILURE = 'SET_SERVICER_ON_CHANGE_FAILURE',
    CREATE_SERVICER_IS_LOADING = 'CREATE_SERVICER_IS_LOADING',
    CREATE_SERVICER_SUCCESS = 'CREATE_SERVICER_SUCCESS',
    CREATE_SERVICER_FAILURE = 'CREATE_SERVICER_FAILURE',
    UPDATE_SERVICER_IS_LOADING = 'UPDATE_SERVICER_IS_LOADING',
    UPDATE_SERVICER_SUCCESS = 'UPDATE_SERVICER_SUCCESS',
    UPDATE_SERVICER_FAILURE = 'UPDATE_SERVICER_FAILURE',
    DELETE_SERVICER_IS_LOADING = 'DELETE_SERVICER_IS_LOADING',
    DELETE_SERVICER_SUCCESS = 'DELETE_SERVICER_SUCCESS',
    DELETE_SERVICER_FAILURE = 'DELETE_SERVICER_FAILURE'
}