import { AxiosError } from "axios";
import { IProductsHistory } from "../../services/api/api.interfaces";
import { IProducts } from "../../pages/Inventory/Product.type";

export enum InventoryActionTypes {
    SET_ALL_PRODUCTS_IS_LOADING = 'SET_ALL_PRODUCTS_IS_LOADING',
    SET_ALL_PRODUCTS_SUCCESS = 'SET_ALL_PRODUCTS_SUCCESS',
    SET_ALL_PRODUCTS_IS_FAILURE = 'SET_ALL_PRODUCTS_IS_FAILURE',
    SET_PRODUCT_WITH_PAGINATION_IS_LOADING = 'SET_PRODUCT_WITH_PAGINATION_IS_LOADING',
    SET_PRODUCT_WITH_PAGINATION_SUCCESS = 'SET_PRODUCT_WITH_PAGINATION_IS_SUCCESS',
    SET_PRODUCT_WITH_PAGINATION_FAILURE = 'SET_PRODUCT_WITH_PAGINATION_IS_FAILURE',
    CREATE_PRODUCT_IS_LOADING = 'CREATE_PRODUCT_IS_LOADING',
    CREATE_PRODUCT_SUCCESS = 'CREATE_PRODUCT_SUCCESS',
    CREATE_PRODUCT_FAILURE = 'CREATE_PRODUCT_FAILURE',
    UPDATE_PRODUCT_IS_LOADING = 'UPDATE_PRODUCT_IS_LOADING',
    UPDATE_PRODUCT_SUCCESS = 'UPDATE_PRODUCT_SUCCESS',
    UPDATE_PRODUCT_FAILURE = 'UPDATE_PRODUCT_FAILURE',
    DELETE_PRODUCT_IS_LOADING = 'DELETE_PRODUCT_IS_LOADING',
    DELETE_PRODUCT_SUCCESS = 'DELETE_PRODUCT_SUCCESS',
    DELETE_PRODUCT_FAILURE = 'DELETE_PRODUCT_FAILURE',
    SET_PRODUCT_ON_CHANGE_IS_LOADING = 'SET_PRODUCT_ON_CHANGE_IS_LOADING',
    SET_PRODUCT_ON_CHANGE_SUCCESS = 'SET_PRODUCT_ON_CHANGE_SUCCESS',
    SET_PRODUCT_ON_CHANGE_FAILURE = 'SET_PRODUCT_ON_CHANGE_FAILURE',
    SET_PRODUCTHISTORY_BY_ID_IS_LOADING = 'SET_PRODUCTHISTORY_BY_ID_IS_LOADING',
    SET_PRODUCTHISTORY_BY_ID_SUCCESS = 'SET_PRODUCTHISTORY_BY_ID_SUCCESS',
    SET_PRODUCTHISTORY_BY_ID_FAILURE = 'SET_PRODUCTHISTORY_BY_ID_FAILURE',
    SET_PRODUCT_BY_ID_IS_LOADING = 'SET_PRODUCT_BY_ID_IS_LOADING',
    SET_PRODUCT_BY_ID_FAILURE = 'SET_PRODUCT_BY_ID_FAILURE',
    SET_PRODUCT_BY_ID_SUCCESS = 'SET_PRODUCT_BY_ID_SUCCESS',
}

export const setAllProductsIsLoading = () => ({
    type: InventoryActionTypes.SET_ALL_PRODUCTS_IS_LOADING
});

export const setAllProductsFailure = (error: AxiosError) => ({
    type: InventoryActionTypes.SET_ALL_PRODUCTS_IS_FAILURE,
    payload: error
});

export const setAllProductsSuccess = (data: IProducts[]) => ({
    type: InventoryActionTypes.SET_ALL_PRODUCTS_SUCCESS,
    payload: data
});

export const setCreateProductIsLoading = () => ({
    type: InventoryActionTypes.CREATE_PRODUCT_IS_LOADING
});

export const setCreateProductFailure = (error: string) => ({
    type: InventoryActionTypes.CREATE_PRODUCT_FAILURE,
    payload: error,
});

export const setCreateProductSuccess = (data: IProducts) => ({
    type: InventoryActionTypes.CREATE_PRODUCT_SUCCESS,
    payload: data,
});

export const setProductWithPaginationIsLoading = () => ({
    type: InventoryActionTypes.SET_PRODUCT_WITH_PAGINATION_IS_LOADING,
});

export const setProductWithPaginationFailure = (error: string) => ({
    type: InventoryActionTypes.SET_PRODUCT_WITH_PAGINATION_FAILURE,
    payload: error,
});

export const setProductWithPaginationSuccess = (data: IProducts[]) => ({
    type: InventoryActionTypes.SET_PRODUCT_WITH_PAGINATION_SUCCESS,
    payload: data,
});

export const setUpdateProductIsLoading = () => ({
    type: InventoryActionTypes.UPDATE_PRODUCT_IS_LOADING
});

export const setUpdateProductFailure = (error: string) => ({
    type: InventoryActionTypes.UPDATE_PRODUCT_FAILURE,
    payload: error,
});

export const setUpdateProductSuccess = (data: IProducts) => ({
    type: InventoryActionTypes.UPDATE_PRODUCT_SUCCESS,
    payload: data,
});

export const setDeleteProductIsLoading = () => ({
    type: InventoryActionTypes.DELETE_PRODUCT_IS_LOADING
});

export const setDeleteProductFailure = (error: string) => ({
    type: InventoryActionTypes.CREATE_PRODUCT_FAILURE,
    payload: error
});

export const setDeleteProductSuccess = () => ({
    type: InventoryActionTypes.DELETE_PRODUCT_SUCCESS,
});

export const setSearchProductsIsLoading = () => ({
    type: InventoryActionTypes.SET_PRODUCT_ON_CHANGE_IS_LOADING
});

export const setSearchProductsFailure = (error: string) => ({
    type: InventoryActionTypes.SET_PRODUCT_ON_CHANGE_FAILURE,
    payload: error
});

export const setSearchProductsSuccess = (data: IProducts[]) => ({
    type: InventoryActionTypes.SET_PRODUCT_ON_CHANGE_SUCCESS,
    payload: data
});

export const setProductHistoryByIdIsLoading = () => ({
    type: InventoryActionTypes.SET_PRODUCTHISTORY_BY_ID_IS_LOADING,
});

export const setProductHistoryByIdFailure = (error: string) => ({
    type: InventoryActionTypes.SET_PRODUCTHISTORY_BY_ID_FAILURE,
    payload: error,
});

export const setProductHistoryByIdSuccess = (data: IProductsHistory[]) => ({
    type: InventoryActionTypes.SET_PRODUCTHISTORY_BY_ID_SUCCESS,
    payload: data,
});
export const setProductByIdIsLoading = () => ({
    type: InventoryActionTypes.SET_PRODUCT_BY_ID_IS_LOADING
})
export const setProductByIdFailure = (error: AxiosError) => ({
    type: InventoryActionTypes.SET_PRODUCT_BY_ID_FAILURE,
    payload: error
})
export const setProductByIdSuccess = (data: IProducts) => ({
    type: InventoryActionTypes.SET_PRODUCT_BY_ID_SUCCESS,
    payload: data
})

interface SetAllProductsIsLoading {
    type: InventoryActionTypes.SET_ALL_PRODUCTS_IS_LOADING
}

interface SetAllProductsFailure {
    type: InventoryActionTypes.SET_ALL_PRODUCTS_IS_FAILURE,
    payload: string
}

interface SetAllProductsSuccess {
    type: InventoryActionTypes.SET_ALL_PRODUCTS_SUCCESS,
    payload: IProducts[]
}

interface SetCreateProductIsLoading {
    type: InventoryActionTypes.CREATE_PRODUCT_IS_LOADING,
}

interface SetCreateProductFailure {
    type: InventoryActionTypes.CREATE_PRODUCT_FAILURE,
    payload: string
}

interface SetCreateProductSuccess {
    type: InventoryActionTypes.CREATE_PRODUCT_SUCCESS,
    payload: IProducts
}

interface SetProductWithPaginationIsLoading {
    type: InventoryActionTypes.SET_PRODUCT_WITH_PAGINATION_IS_LOADING
}

interface SetProductWithPaginationFailure {
    type: InventoryActionTypes.SET_PRODUCT_WITH_PAGINATION_FAILURE,
    payload: string
}

interface SetProductWithPaginationSuccess {
    type: InventoryActionTypes.SET_PRODUCT_WITH_PAGINATION_SUCCESS,
    payload: IProducts[]
}

interface SetUpdateProductIsLoading {
    type: InventoryActionTypes.UPDATE_PRODUCT_IS_LOADING
}

interface SetUpdateProductFailure {
    type: InventoryActionTypes.UPDATE_PRODUCT_FAILURE,
    payload: string
}

interface SetUpdateProductSuccess {
    type: InventoryActionTypes.UPDATE_PRODUCT_SUCCESS,
    payload: IProducts
}

interface SetDeleteProductIsLoading {
    type: InventoryActionTypes.DELETE_PRODUCT_IS_LOADING
}

interface SetDeleteProductFailure {
    type: InventoryActionTypes.DELETE_PRODUCT_FAILURE,
    payload: string
}
interface SetDeleteProductSuccess {
    type: InventoryActionTypes.DELETE_PRODUCT_SUCCESS
}

interface SetSearchProductsIsLoading {
    type: InventoryActionTypes.SET_PRODUCT_ON_CHANGE_IS_LOADING
}

interface SetSearchProductsFailure {
    type: InventoryActionTypes.SET_PRODUCT_ON_CHANGE_FAILURE,
    payload: string
}

interface SetSearchProductsSuccess {
    type: InventoryActionTypes.SET_PRODUCT_ON_CHANGE_SUCCESS,
    payload: IProducts[]
}

interface SetProductHistoryByIdIsLoading {
    type: InventoryActionTypes.SET_PRODUCTHISTORY_BY_ID_IS_LOADING
}

interface SetProductHistoryByIdFailure {
    type: InventoryActionTypes.SET_PRODUCTHISTORY_BY_ID_FAILURE,
    payload: string
}

interface SetProductHistoryByIdSuccess {
    type: InventoryActionTypes.SET_PRODUCTHISTORY_BY_ID_SUCCESS,
    payload: IProductsHistory[]
}
interface SetProductByIdIsLoading {
    type: InventoryActionTypes.SET_PRODUCT_BY_ID_IS_LOADING
}
interface SetProductByIdFailure {
    type: InventoryActionTypes.SET_PRODUCT_BY_ID_FAILURE,
    payload: string
}
interface SetProductByIdSuccess {
    type: InventoryActionTypes.SET_PRODUCT_BY_ID_SUCCESS,
    payload: IProducts
}

export type ProductsActionTypes =
    | SetAllProductsIsLoading
    | SetAllProductsFailure
    | SetAllProductsSuccess
    | SetCreateProductIsLoading
    | SetCreateProductFailure
    | SetCreateProductSuccess
    | SetProductWithPaginationIsLoading
    | SetProductWithPaginationFailure
    | SetProductWithPaginationSuccess
    | SetUpdateProductIsLoading
    | SetUpdateProductFailure
    | SetUpdateProductSuccess
    | SetDeleteProductIsLoading
    | SetDeleteProductFailure
    | SetDeleteProductSuccess
    | SetSearchProductsIsLoading
    | SetSearchProductsFailure
    | SetSearchProductsSuccess
    | SetProductHistoryByIdIsLoading
    | SetProductHistoryByIdFailure
    | SetProductHistoryByIdSuccess
    | SetProductByIdIsLoading
    | SetProductByIdFailure
    | SetProductByIdSuccess;