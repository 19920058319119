export enum ClientsActionTypes {
    SET_ALL_CLIENTS_IS_LOADING = 'SET_ALL_CLIENTS_IS_LOADING',
    SET_ALL_CLIENTS_SUCCESS = 'SET_ALL_CLIENTS_SUCCESS',
    SET_ALL_CLIENTS_FAILURE = 'SET_ALL_CLIENTS_FAILURE',
    SET_CLIENTS_WITH_PAGINATION_IS_LOADING = 'SET_CLIENTS_WITH_PAGINATION_IS_LOADING',
    SET_CLIENTS_WITH_PAGINATION_SUCCESS = 'SET_CLIENTS_WITH_PAGINATION_SUCCESS',
    SET_CLIENTS_WITH_PAGINATION_FAILURE = 'SET_CLIENTS_WITH_PAGINATION_FAILURE',
    SET_CLIENT_ON_CHANGE_IS_LOADING = 'SET_CLIENT_ON_CHANGE_IS_LOADING',
    SET_CLIENT_ON_CHANGE_SUCCESS = 'SET_CLIENT_ON_CHANGE_SUCCESS',
    SET_CLIENT_ON_CHANGE_FAILURE = 'SET_CLIENT_ON_CHANGE_FAILURE',
    CREATE_CLIENT_IS_LOADING = 'CREATE_CLIENT_IS_LOADING',
    CREATE_CLIENT_SUCCESS = 'CREATE_CLIENT_SUCCESS',
    CREATE_CLIENT_FAILURE = 'CREATE_CLIENT_FAILURE',
    UPDATE_CLIENT_IS_LOADING = 'UPDATE_CLIENT_IS_LOADING',
    UPDATE_CLIENT_SUCCESS = 'UPDATE_CLIENT_SUCCESS',
    UPDATE_CLIENT_FAILURE = 'UPDATE_CLIENT_FAILURE',
    DELETE_CLIENT_IS_LOADING = 'DELETE_CLIENT_IS_LOADING',
    DELETE_CLIENT_SUCCESS = 'DELETE_CLIENT_SUCCESS',
    DELETE_CLIENT_FAILURE = 'DELETE_CLIENT_FAILURE',
    GET_CLIENT_BY_ID_IS_LOADING = 'GET_CLIENT_BY_ID_IS_LOADING',
    GET_CLIENT_BY_ID_FAILURE = 'GET_CLIENT_BY_ID_FAILURE',
    GET_CLIENT_BY_ID_SUCCESS = 'GET_CLIENT_BY_ID_SUCCESS'
}