export enum NavigatorStackPages {
  Login = 'login',
  Home = 'home',
  Dashboard = 'dashboard',
  Users = 'users',
  Inventory = 'inventory',
  Machines = 'machines',
  Tasks = 'tasks',
  Finances = 'finances',
  Settings = 'settings',
  ForgotPassword = 'forgotPassword',
  ConfirmationSent = 'confirmationSent',
  ResetPassword = 'resetPassword',
  EmailConfirmation = 'confirm-email',
  ProductHistory = 'inventory/product-history',
  UsersMachines = 'users/users-machines'
}
