import { IUsers } from "../../services/api/api.interfaces";
import { ClientsActionTypes } from "./clients";
import { ServicersActionTypes } from "./servicers";
import { UsersActionTypes } from "./users.action";

export interface State {
    users: IUsers[],
    servicer: IUsers | undefined,
    isLoading: boolean,
    error: string | undefined;
    client: IUsers | undefined;
}

const usersInitialState: State = {
    users: [],
    servicer: undefined,
    isLoading: false,
    error: undefined,
    client: undefined
};

const usersReducers = (state = usersInitialState, action: UsersActionTypes) => {
    switch (action.type) {
        case ServicersActionTypes.SET_ALL_SERVICERS_IS_LOADING:
            return {
                ...state,
                isLoading: true,
            };
        case ServicersActionTypes.SET_ALL_SERVICERS_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            };
        case ServicersActionTypes.SET_ALL_SERVICERS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                users: action.payload,
            };
        case ServicersActionTypes.CREATE_SERVICER_IS_LOADING:
            return {
                ...state,
                isLoading: true,
            };
        case ServicersActionTypes.CREATE_SERVICER_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            };
        case ServicersActionTypes.CREATE_SERVICER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                servicer: action.payload,
            };
        case ServicersActionTypes.UPDATE_SERVICER_IS_LOADING:
            return {
                ...state,
                isLoading: true,
            };
        case ServicersActionTypes.UPDATE_SERVICER_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            };
        case ServicersActionTypes.UPDATE_SERVICER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                servicer: action.payload,
            };
        case ServicersActionTypes.DELETE_SERVICER_IS_LOADING:
            return {
                ...state,
                isLoading: true,
            };
        case ServicersActionTypes.DELETE_SERVICER_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            };
        case ServicersActionTypes.DELETE_SERVICER_SUCCESS:
            return {
                ...state,
                isLoading: false,
            };
        case ServicersActionTypes.SET_SERVICER_ON_CHANGE_IS_LOADING:
            return {
                ...state,
                isLoading: true,
            };
        case ServicersActionTypes.SET_SERVICER_ON_CHANGE_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            };
        case ServicersActionTypes.SET_SERVICER_ON_CHANGE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                users: action.payload,
            };
        case ServicersActionTypes.SET_SERVICERS_WITH_PAGINATION_IS_LOADING:
            return {
                ...state,
                isLoading: true,
            };
        case ServicersActionTypes.SET_SERVICERS_WITH_PAGINATION_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            };
        case ServicersActionTypes.SET_SERVICERS_WITH_PAGINATION_SUCCESS:
            return {
                ...state,
                isLoading: false,
                users: action.payload,
            };
        case ClientsActionTypes.CREATE_CLIENT_IS_LOADING:
            return {
                ...state,
                isLoading: true,
            };
        case ClientsActionTypes.CREATE_CLIENT_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            };
        case ClientsActionTypes.CREATE_CLIENT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                client: action.payload,
            };
        case ClientsActionTypes.SET_ALL_CLIENTS_IS_LOADING:
            return {
                ...state,
                isLoading: true,
            };
        case ClientsActionTypes.SET_ALL_CLIENTS_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            };
        case ClientsActionTypes.SET_ALL_CLIENTS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                users: action.payload,
            };
        case ClientsActionTypes.SET_CLIENT_ON_CHANGE_IS_LOADING:
            return {
                ...state,
                isLoading: true,
            };
        case ClientsActionTypes.SET_CLIENT_ON_CHANGE_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            };
        case ClientsActionTypes.SET_CLIENT_ON_CHANGE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                users: action.payload,
            };
        case ClientsActionTypes.SET_CLIENTS_WITH_PAGINATION_IS_LOADING:
            return {
                ...state,
                isLoading: true,
            };
        case ClientsActionTypes.SET_CLIENTS_WITH_PAGINATION_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            };
        case ClientsActionTypes.SET_CLIENTS_WITH_PAGINATION_SUCCESS:
            return {
                ...state,
                isLoading: false,
                users: action.payload,
            };
        case ClientsActionTypes.UPDATE_CLIENT_IS_LOADING:
            return {
                ...state,
                isLoading: true,
            };
        case ClientsActionTypes.UPDATE_CLIENT_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            };
        case ClientsActionTypes.UPDATE_CLIENT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                client: action.payload,
            };
        case ClientsActionTypes.DELETE_CLIENT_IS_LOADING:
            return {
                ...state,
                isLoading: true,
            };
        case ClientsActionTypes.DELETE_CLIENT_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            };
        case ClientsActionTypes.DELETE_CLIENT_SUCCESS:
            return {
                ...state,
                isLoading: false,
            };
        case ClientsActionTypes.GET_CLIENT_BY_ID_IS_LOADING:
            return {
                ...state,
                isLoading: true
            }
        case ClientsActionTypes.GET_CLIENT_BY_ID_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload
            }
        case ClientsActionTypes.GET_CLIENT_BY_ID_SUCCESS:
            return {
                ...state,
                isLoading: false,
                client: action.payload
            }
        default:
            return state;
    }
};
export default usersReducers;